const APIEndpoint = process.env.REACT_APP_STUBS_API_ENDPOINT;

export const ApiCaller = async (apiFunction, apiQuery) => {
  try {
    // Construct the full API endpoint URL
    //const apiUrl = `${APIEndpoint}${apiFunction}?${new URLSearchParams(apiQuery).toString()}`;

    const apiUrl = `https://${apiFunction.replace("_","-")}${APIEndpoint}?${new URLSearchParams(apiQuery).toString()}`;
    //console.log('API URL:', apiUrl);
    //console.log(apiUrl);
    // Perform the GET request
    const response = await fetch(apiUrl, {
      method: 'GET', // or 'POST' if your API requires
      headers: {
        'Content-Type': 'application/json',
        // Include other headers as required by your backend
      },
      // If your API requires a POST request, uncomment the following line
      // body: JSON.stringify(apiQuery),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Parse the JSON response

    // Check if the response is an array with a single object
    //if (Array.isArray(data) && data.length === 1) {
    //  console.log(data[0]);
    //  return data[0]; // Return the first element
    //}
    //console.log(data);
    return data; // Return the response data
  } catch (error) {
      console.error("Error calling the API:", error);
      return null; // Return null in case of an error
  }
};