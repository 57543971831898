import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainSearch from './components/mainsearch';
import ShowResults from './components/showresults_new';
import PersonProfile from './components/personprofile';
import ScrollToTop from './components/scrollToTop';
import WhyStubs from './components/WhyStubs';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQ from './components/FAQ';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<MainSearch />} />
          <Route path="/results" element={<ShowResults />} />
          <Route path="/person/:id" element={<PersonProfile />} />
          <Route path="/why-stubs" element={<WhyStubs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
