import React, { useState, useEffect } from 'react';
import { ApiCaller } from '../utils/APIConnect';
import { fetchApi } from '../utils/APICall';
import { Box, Heading, Text, VStack, HStack, Button, Link, Icon, Tabs, TabList, Tab, TabPanels, TabPanel, Spinner } from '@chakra-ui/react';
import { useLocation, NavLink } from 'react-router-dom';
import { BsLinkedin, BsAt, BsPersonCircle } from "react-icons/bs";
import { convertToTitleCase } from '../utils/textFunctions';
import StubsHeader from './Header';
import StubsFooter from './Footer';

const ShowResults = () => {
  const location = useLocation();
  const { searchTerm } = location.state || { searchTerm: '' }; // Get the search term from state

  const [apiResponse, setApiResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 25; // Limit results to 25
  const [activeTab, setActiveTab] = useState(0); // Default to "By Name"
  const [fullResults, setFullResults] = useState([]); // To store detailed results
  const [loading, setLoading] = useState(false); // Loading state

  const fetchFullResults = async () => {
    let detailedResults = [];
    try {
        setLoading(true); // Start loading
        if (activeTab === 0) {
            // Fetch detailed results for people
            detailedResults = await ApiCaller('searchPeople_expanded', { name: searchTerm });
        } else if (activeTab === 1) {
            // Fetch detailed results for job titles
            detailedResults = await ApiCaller('searchPeople_byTitle', { jobtitle: searchTerm });
        } else if (activeTab === 2) {
            // Fetch detailed results for organizations
            detailedResults = await ApiCaller('searchPeople_byOrg', { lookup: searchTerm });
        }

        setFullResults(detailedResults);
    } catch (error) {
        console.error("Error fetching full results:", error);
        setFullResults([]); // Set to empty array on error
    } finally {
        setLoading(false); // End loading
    }
  };

  useEffect(() => {
      fetchFullResults();
  }, [activeTab]); // Only depend on activeTab

  // Calculate the current results
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = fullResults.slice(indexOfFirstResult, indexOfLastResult);

  // Calculate total pages
  const totalPages = Math.ceil(fullResults.length / resultsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh" padding={4}>
      <StubsHeader />

      {/* Left Column (20% White Space) */}
      <Box flex="0.1" bg="white" />

      {/* Right Column Container */}
      <Box flex="0.9" display="flex" flexDirection="column" padding={4} mt={16}>
        {/* Search Results Heading */}
        <Heading size="lg" width="100%" mb={4}>
          Search Results for: {searchTerm}
        </Heading>

        {/* Tabs for Search Types */}
        <Tabs onChange={(index) => setActiveTab(index)} defaultIndex={0}>
          <TabList>
            <Tab>By Name</Tab>
            <Tab>By Job Title</Tab>
            <Tab>By Organization</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* Displaying results for By Name */}
              {loading ? (
                <Spinner size="xl" />
              ) : (
                <ResultsList results={currentResults} totalCount={fullResults.length}/>
              )}
            </TabPanel>
            <TabPanel>
              {/* Displaying results for By Job Title */}
              {loading ? (
                <Spinner size="xl" />
              ) : (
                <ResultsList results={currentResults} totalCount={fullResults.length} />
              )}
            </TabPanel>
            <TabPanel>
              {/* Displaying results for By Organization */}
              {loading ? (
                <Spinner size="xl" />
              ) : (
                <ResultsList results={currentResults} totalCount={fullResults.length} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* Pagination Controls */}
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Box>

      <StubsFooter />
    </Box>
  );
};

// Component to render the list of results
const ResultsList = ({ results, totalCount }) => {
  const safeResults = results || []; // Default to an empty array if results is null or undefined

  return (
    <Box flex="1" padding={4} bg="#F7FAFC">
      <VStack spacing={6} align="start">
        <Box borderWidth="1px" borderRadius="md" padding={4} bg="white" width="100%">
        {safeResults.length > 0 ? (
            <>
            <Heading size="md" mb={2}>Search Results</Heading>
            <Text fontSize="md" color="gray.600" mt={2} mb={4}>
              <strong>We found {totalCount} search results.</strong>
            </Text>
            </>
        ) : (
            <Spinner size="xl" />
        )}

          {/* Current Results */}
          {safeResults.length > 0 ? (
            safeResults.map((result, index) => (
              <Box key={index} borderWidth="1px" borderRadius="md" padding={4} mb={4} bg="white" width="100%">
                <VStack spacing={4} align="start">
                  <NavLink to={`/person/${result.id}`} style={{ textDecoration: 'none' }}>
                    <HStack spacing={5}>
                      <Icon as={BsPersonCircle} boxSize={12} color="teal.500" />
                      <HStack>
                        <VStack align="start" spacing={0}>
                          <Heading size="md">{convertToTitleCase(result.person_name)}</Heading>
                          <Text fontSize="lg">{convertToTitleCase(result.title)}</Text>
                        </VStack>
                      </HStack>
                    </HStack>
                  </NavLink>
                  <Text fontWeight="bold" mb={1}>{convertToTitleCase(result.name)}</Text>
                  <Text>{result.street}, {result.zip}, {result.state}</Text>
                  <HStack spacing={4}>
                    <Link href={`mailto:${result.email}`}>
                      <Button leftIcon={<BsAt />} variant="ghost" colorScheme="teal">
                        Email
                      </Button>
                    </Link>
                    <Link href={result.linkedin} isExternal>
                      <Button leftIcon={<BsLinkedin />} variant="ghost" colorScheme="teal">
                        LinkedIn
                      </Button>
                    </Link>
                  </HStack>
                </VStack>
              </Box>
            ))
          ) : (
            <Text>No results found.</Text> // Message when there are no results
          )}
        </Box>
      </VStack>
    </Box>
  );
};

// Component for pagination controls
const PaginationControls = ({ currentPage, totalPages, onPageChange }) => (
  <Box display="flex" justifyContent="center" alignItems="center" mt={4} mb={4}>
    {/* First Page Button */}
    {currentPage > 1 && (
      <Button onClick={() => onPageChange(1)} colorScheme="teal" mx={1}>
        First
      </Button>
    )}
    {/* Previous Page Button */}
    {currentPage > 1 && (
      <Button onClick={() => onPageChange(currentPage - 1)} colorScheme="teal" mx={1}>
        Previous
      </Button>
    )}
    {/* Page Numbers */}
    {Array.from({ length: totalPages }, (_, index) => {
      const pageNumber = index + 1;
      if (
        pageNumber === 1 ||
        pageNumber === totalPages ||
        (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
      ) {
        return (
          <Button
            key={index}
            onClick={() => onPageChange(pageNumber)}
            variant={currentPage === pageNumber ? 'solid' : 'outline'}
            colorScheme="teal"
            mx={1}
          >
            {pageNumber}
          </Button>
        );
      }
      return null;
    })}
    {/* Next Page Button */}
    {currentPage < totalPages && (
      <Button onClick={() => onPageChange(currentPage + 1)} colorScheme="teal" mx={1}>
        Next
      </Button>
    )}
    {/* Last Page Button */}
    {currentPage < totalPages && (
      <Button onClick={() => onPageChange(totalPages)} colorScheme="teal" mx={1}>
        Last
      </Button>
    )}
  </Box>
);

export default ShowResults;